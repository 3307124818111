import axios from "axios";
import {

  ajaxGet,
  ajaxPost,
  
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
// export const loadBannerListApi = (params={}) =>ajaxGet({url:'banner/list', params})
export const loadParentOrdersListApi = (params={}) =>ajaxGet({url:'mall/sass/parent/orders/list', params})
export const loadCustomerParentOrdersListApi = (params={}) =>ajaxGet({url:'mall/sass/merge-orders/customer/order/list', params})
export const loadSassOrdersListApi = (params={}) =>ajaxGet({url:'mall/sass/merge-orders/list', params})

export const loadMergeOrdersDetailApi = (params={}) =>ajaxGet({url:'mall/sass/merge-orders/merge/detail', params})

export const  setOrdersPackageApi = (params={}) =>ajaxPost({url:'mall/sass/merge-orders/set-package', params})
export const  setOrdersWeightApi = (params={}) =>ajaxPost({url:'mall/sass/merge-orders/set-weight', params})
export const  sendProductApi = (params={}) =>ajaxPost({url:'mall/sass/merge-orders/send/product', params})
export const  mergeOrdersApi = (params={}) =>ajaxPost({url:'mall/sass/merge-orders/handle/merge', params})

export const  loadOrderExpressListApi = (params={}) =>ajaxGet({url:'mall/sass/delivery/rule/list', params})
export const  loadOrderDeliveryRuleListApi = (params={}) =>ajaxGet({url:'mall/sass/orders/delivery/rule/list', params})

export const  compueteOrderPostageApi = (params={}) =>ajaxPost({url:'mall/sass/orders/compute/postage', params})
export const  agreePlatformRefundApi = (params={}) =>ajaxPost({url:'mall/orders/refund/agree/v2', params})


export const  allProductCheckinApi = (params={}) =>ajaxPost({url:'mall/orders/product/all/checkin', params})



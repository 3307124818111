<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                                          icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handelAddClick" type="primary" icon="Plus"
                                          v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
      </el-form>
    </div>
     <div style="margin-top: 10px;margin-left: 6px;">
		 <p>说明:启用状态后将会在各子系统同步启动相关物流箱，物流箱规格由集花宝后台统一集中管理，各子系统可以在相关页面设置自己的加价</p>
	 </div>
    <div class="table" style="margin-top: 8px;">
      <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
        <el-table-column  width="120" align="center" prop="id" label="序号">
           
        </el-table-column>
        <el-table-column width="150" prop="name" label="名称"></el-table-column>
        <el-table-column width="150" prop="volume" label="容量"></el-table-column>
        <el-table-column width="150" prop="fee" label="费用"></el-table-column>
        <el-table-column width="200" label="状态">
          <template #default="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                       @change="changeStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" >
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                              v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                  v-show="hasPermission(this.$route.name, 'Delete')">删除</span> 
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <addExpressBox ref="addExpressFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
    </addExpressBox>
  </div>
</template>
<script>
import addExpressBox from "./components/addExpressBox";
import Pagination from "@/components/Pagination";
import {dateFormats, getTableHeight, hasPermission} from "@/utils/util";
import {
  ExpressBoxDisable,
  ExpressBoxSearch,
  ExpressBoxDelete,
} from '@/api/api'
import {loadExpressBoxListApi,expressBoxManageApi} from '@/api/express'
export default {
  components: {
    Pagination,
    addExpressBox
  },
  name: 'expressManager',
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
		status:2,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      identityList: [
        { id: 0, name: '普通用户' },
        { id: 1, name: '会员' },
      ],
      timers: [],
      tableData: [],
      isDialogVisible: false,
    }
  },

  methods: {
    hasPermission,
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    changeStatus(row) {
	  let act_type=""
	  if(row.status){
		  act_type="start"
	  }else{
		  act_type="stop"
	  }
	  this.manageStatus(row.id,act_type)
	  
    },
	manageStatus(id,act_type){
		var that=this
		let msg=""
		
		if(act_type==="start"){
			msg="确定启用该物流箱箱吗？如需给各子系统使用，请启用后点击发布到子系统"
		}
		if(act_type==="stop"){
			msg="确定停用该物流箱箱吗？各子系统也将同步停用，请慎重操作！"
		}
		if(act_type==="del"){
			msg="确定删除该物流箱箱吗？"
		}
		
		if(act_type==="publish"){
			msg="该物流箱规格将在各子系统小程序中使用，请及时通知各子系统运营者检查有没有添加成功！如子系统没有添加成功，各子系统可以手动在相关页面添加！已添加的不会受影响"
		}
		that.loadingPage=true
		that.$confirm(msg,"提示",{
			closeOnClickModal:false,
			type:"warning"
		}).then(()=>{
			expressBoxManageApi({id:id,act_type:act_type}).then(res=>{
				that.loadingPage=false
				if(res.code===2000){
					that.$message.success(res.msg)
					that.getData()
				}else{
					that.$message.error(res.msg)
				}
			})
		}).catch(()=>{
			that.loadingPage=false
		})
		
	},
    handelAddClick() {
      this.isDialogVisible = true
      this.$nextTick(() => {
        this.$refs.addExpressFlag.handleOpen(null, "新增")
      })
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.isDialogVisible = true
        this.$nextTick(() => {
          this.$refs.addExpressFlag.handleOpen(row, "编辑")
        })
      }
      else if (flag == 'disable') {
        let vm = this
        ExpressBoxDisable({ id: row.id }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          ExpressBoxDelete({ id: row.id }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10,
		   status:2,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0,
		 
        }
        this.getData()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportDataBackend() {
      var params = {
        page: 1,
        limit: 9999,
      }
      // UsersUsersExportexecl(params).then(res => {
      //     if (res.code == 2000) {
      //         this.downloadFileURL(res.data.data)
      //         //this.$message.warning(res.data.data)
      //     }
      // })
    },
    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      loadExpressBoxListApi(this.formInline).then(res => {
        this.loadingPage = false
	
        if (res.code == 2000) {
          this.tableData = res.data.data_list
          this.pageparm.page=res.data.page
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }

  },
  created() {
    this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>

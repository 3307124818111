<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="名称：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60" clearable placeholder="手机号" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                
                <el-form-item label="状态：">
                    <el-select size="default" v-model="formInline.is_active" placeholder="请选择" clearable style="width: 90px"
                        @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                       >查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'add')" type="primary" icon="Plus"
                       >新增</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="100" align="center" label="序号" prop="id">
                   <!-- <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template> -->
                </el-table-column>
                <!-- <el-table-column min-width="100" prop="phone" label="账号"></el-table-column> -->
                <el-table-column width="150" prop="app_name"  label="小程序名称" show-overflow-tooltip >
					
				</el-table-column>
				 <el-table-column width="120"  label="系统简称" >
					<template #default="scope">
						<el-text >{{scope.row.short_name}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <!-- <span v-if="scope.row.is_super" style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;">超代</span> -->
					</template>
				</el-table-column>
                <el-table-column width="150" prop="phone" label="联系人手机号"></el-table-column>
                <el-table-column width="150" prop="appid" label="APPID" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="print_name" label="打印名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="100" prop="print_phone" label="服务电话" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="balance" label="账户余额"></el-table-column>
                <el-table-column width="100" prop="is_our" label="内部系统" align="center">
					<template #default="scope">
						<el-tag v-if="scope.row.is_our">是</el-tag>
						<el-tag v-else style="color: red;">否</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="110" label="支付参数">
				    <template #default="scope">
				        <el-tag class="table-operate-btn" @click="handleEdit(scope.row, 'pay-config')"
				            v-show="hasPermission(this.$route.name, 'Update')">查看</el-tag>
				    </template>
				</el-table-column>
                
                <el-table-column width="100" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
				
				<el-table-column width="150" label="重置密码">
                    <template #default="scope">
                        <el-tag class="table-operate-btn" @click="handleEdit(scope.row, 'reset_pwd')"
                            v-show="hasPermission(this.$route.name, 'Update')">重置密码</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span> 
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'recharge')"
                            v-show="hasPermission(this.$route.name, 'Update')">充值</span>
                      
						<span class="table-operate-btn" @click="manageStatus(scope.row, 'add_menu')"
                            v-show="hasPermission(this.$route.name, 'Delete')">生成菜单</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addUser ref="addUserFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addUser>
		<editSuperAgent ref="editSuperAgentRef" v-if="show_edit_super_dialog" @refreshData="getData" @closed="show_edit_super_dialog=false"></editSuperAgent>
		
		<udateAgentPassword ref="updateAgentPwd" @refreshData="getData" v-if="is_show_update_box" @closed="is_show_update_box = false">
        </udateAgentPassword>
		
		
		<el-dialog :title="dialogTitle"  v-model="showDialog" width="40%">
			<el-form ref="dataForm" label-width="150px">
				<el-form-item label="小程序名称" prop="app_name">
					<el-input v-model="dataForm.app_name" style="width: 280px;"></el-input>
				</el-form-item>
				<el-form-item label="小程序简称" prop="short_name">
					<el-input v-model="dataForm.short_name" style="width: 280px;"></el-input>
					<p style="margin-left: 8px;">12字以内</p>
				</el-form-item>
				<el-form-item label="联系人姓名" prop="real_name">
					<el-input v-model="dataForm.real_name" style="width: 280px;"></el-input>
				</el-form-item>
			   <el-form-item label="联系人电话" prop="phone">
					<el-input v-model="dataForm.phone" style="width: 280px;"></el-input>
					<p style="margin-left: 8px;">后台系统的登录账号，只能集花宝后台修改！</p>
				</el-form-item>
				<el-form-item label="登录密码" prop="password" v-if="dataForm.id<1">
					<el-input v-model="dataForm.password" type="password" style="width: 280px;" ></el-input>
				</el-form-item>
				<el-form-item label="打印名称" prop="app_name">
					<el-input v-model="dataForm.print_name" style="width: 280px;"></el-input>
					<p style="margin-left: 8px;">物流小票上的打印名称！</p>
				</el-form-item>
				<el-form-item label="服务电话" prop="print_phone">
					<el-input v-model="dataForm.print_phone" style="width: 280px;"></el-input>
						<p style="margin-left: 8px;">物流小票上的服务电话！</p>
				</el-form-item>
				<el-form-item label="微信APPID" prop="wx_appid">
					<el-input v-model="dataForm.wx_appid" style="width: 280px;"></el-input>
						<p style="margin-left: 8px;">微信小程序appid！</p>
				</el-form-item>
				<el-form-item label="微信Secret" prop="wx_secret">
					<el-input v-model="dataForm.wx_secret" style="width: 280px;"></el-input>
						<p style="margin-left: 8px;">微信小程序secret！</p>
				</el-form-item>
				<el-form-item label="内部系统" prop="is_our">
					<el-radio-group v-model="dataForm.is_our">
						<el-radio :label="true">是</el-radio>
						<el-radio :label="false">否</el-radio>
					</el-radio-group>
					<p style="margin-left:6px;font-size:16px">内部系统可以无限下单</p>
				</el-form-item>
				
				<el-form-item >
					<el-button  @click="showDialog=false">关闭</el-button>
					<el-button type="primary" @click="save">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog :title="pwdDialogTitle"  v-model="showResetPwdDialog" width="30%">
			<el-form ref="pwdForm" label-width="150px">
				<el-form-item label="新密码" prop="app_name">
					<el-input v-model="pwdForm.password" style="width: 280px;"></el-input>
				</el-form-item>
				
				<el-form-item >
					<el-button  @click="showResetPwdDialog=false">关闭</el-button>
					<el-button type="primary" @click="savePwd">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :title="rechargeDialogTitle"  v-model="showRechargeDialog" width="30%">
			<el-form ref="rechargeForm" label-width="150px">
				<el-form-item label="充值金额" prop="balance">
					<el-input v-model="rechargeForm.balance" style="width: 280px;"></el-input>
				</el-form-item>
				
				<el-form-item >
					<el-button  @click="showRechargeDialog=false">关闭</el-button>
					<el-button type="primary" @click="saveRecharge">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :title="payConfigDialogTitle"  v-model="showPayConfigDialog" width="40%">
			<el-form ref="payConfigForm" label-width="150px">
				<el-form-item label="PrivateKey" prop="private_key">
					<el-input type="textarea" v-model="pay_config_form.private_key" ></el-input>
				</el-form-item>
				<el-form-item  label="PublicKey" prop="public_key">
					<el-input  type="textarea" v-model="pay_config_form.public_key" ></el-input>
				</el-form-item>
				<el-form-item label="商户标识" prop="mer_account">
					<el-input v-model="pay_config_form.mer_account" style="width: 280px;"></el-input>
				</el-form-item>
				<el-form-item label="商户编号" prop="mer_no">
					<el-input v-model="pay_config_form.mer_no" style="width: 280px;"></el-input>
				</el-form-item>
				<el-form-item label="支付页提示" prop="pay_title">
					<el-input v-model="pay_config_form.pay_title" style="width: 280px;"></el-input>
				</el-form-item>
				
				<el-form-item >
					<el-button  @click="showPayConfigDialog=false">关闭</el-button>
					<el-button type="primary" @click="savePayConfig">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
		
    </div>
</template>
<script>
import addUser from "./components/addUserAgent";
import editSuperAgent from "./components/editSuperAgent";
import udateAgentPassword from "./components/updateAgentPassword";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersAgentUsers, UsersAgentUsersDelete,manageAgentStatus,editAgentSalesmanApi } from '@/api/api'
import { loadAppListApi,addAppApi,editAppApi,manageAppStatusApi,resetAppPassworodApi,rechargeAppBalanceApi,setAppPayConfigApi } from '@/api/app_manage'

import { operateSuperAgentAPi } from '@/api/super_agent.js'
import { updateAgentSendTypeApi } from '@/api/agent'
export default {
    components: {
        Pagination,
        addUser,
		udateAgentPassword,
		editSuperAgent
    },
    name: 'agentUserManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			is_show_update_box:false,
			is_show_agent_area:false,
			
			show_edit_super_dialog:false,
			salesman_title:"",
			showDialog:false,
			salesman_act_type_list:[{"name":"新增"},{"name":"编辑"}],
			dataForm:{
				id:0,
				app_name:"",
				short_name:"",
				real_name:"",
				phone:"",
				password:"",
				print_name:"",
				print_phone:"",
				is_our:true,
				wx_secret:"",
				wx_appid:"",
				
			},
			pwdForm:{
				id:0,
				password:"",
			},
			rechargeForm:{
				id:0,
				balance:"",
			},
			pay_config_form:{
				appid:"",
				private_key:"",
				public_key:"",
				mer_account:"",
				mer_no:"",
				pay_title:"",
			},
			showResetPwdDialog:false,
			showRechargeDialog:false,
			admin_list:[],
			
			sendtype_form:{
				agent_id:0,
				act_type:"",
				can_self_pickup:false,
				self_pickup_income_ratio:0,
			},
			
			send_type_title:"",
			show_update_send_type:false,
			
			dialogTitle:"添加小程序",
			pwdDialogTitle:"重置密码",
			rechargeDialogTitle:"充值",
			payConfigDialogTitle:"米花支付参数",
			showPayConfigDialog:false,
			
        }
    },

    methods: {
		
		save(){
			
			let msg="确定提交填写的内容吗？"
			
			var that=this
			that.$confirm(msg,"提示",{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>{
				if(that.dataForm.id>0){
					editAppApi(that.dataForm).then(res=>{
						if(res.code==2000){
							that.$message.success(res.msg)
							that.getData()
							that.showDialog=false
						}else{
							that.$message.error(res.msg)
						}
					})
				}else{
					
					addAppApi(that.dataForm).then(res=>{
						if(res.code==2000){
							that.$message.success(res.msg)
							that.getData()
							that.showDialog=false
						}else{
							that.$message.error(res.msg)
						}
					})
				}
				
				
			}).catch(()=>{
				
			})
				
			
			
		},
		
		
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		
		
        changeStatus(row) {
            let that = this
            
			
			let act_type=""
			let msg=""
			if(row.status){
				act_type="start"
				msg="确定启用该小程序吗？"
			}else{
				act_type='stop'
				msg="确定停用确定启用该小程序吗？停用后该程序的用户将不能下单！"
			}
			
			
            that.$confirm(msg, '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
               
                manageAppStatusApi({ id: row.id ,act_type:act_type}).then(res => {
                    if (res.code == 2000) {
                        that.$message.success(res.msg)
                        that.getData()
                    } else {
						row.status=!row.status
                        that.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        
        handleEdit(row, flag) {
			if(flag=="pay-config"){
				this.pay_config_form.appid=row.appid
				this.pay_config_form.private_key=row.private_key
				this.pay_config_form.public_key=row.public_key
				this.pay_config_form.mer_account=row.mer_account
				this.pay_config_form.mer_no=row.mer_no
				this.pay_config_form.pay_title=row.pay_title
				this.showPayConfigDialog=true
				this.payConfigDialogTitle="米花支付参数（"+row.app_name+"）"
				return
			}
			if(flag=="recharge"){
				this.rechargeForm.id=row.id
				this.rechargeForm.balance=0
				this.showRechargeDialog=true
				this.rechargeDialogTitle="余额充值（"+row.app_name+"）"
				return
			}
			if(flag=="reset_pwd"){
				this.pwdForm.id=row.id
				this.pwdForm.password=""
				this.pwdDialogTitle="重置密码 ("+row.app_name+")"
				this.showResetPwdDialog=true
				return
			}
			if(flag=="edit"){
				this.showDialog=true
				this.dataForm=row
				// this.dataForm.id=row.id
				// this.dataForm.app_name=row.app_name
				// this.dataForm.phone=row.phone
				// this.dataForm.real_name=row.real_name
				
				this.dialogTitle="修改小程序"
				return
			}
			if(flag=="add"){
				this.showDialog=true
				this.dataForm.id=0
				this.dataForm.app_name=""
				this.dataForm.phone=""
				this.dataForm.real_name=""
				this.dataForm.password=""
				this.dataForm.short_name=""
				this.dataForm.print_name=""
				this.dataForm.print_phone=""
				this.dialogTitle="新增小程序"
				return
			}
			
            
            if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
				this.dataForm.id=0
				this.dataForm.app_name=""
				this.dataForm.phone=""
				this.dataForm.real_name=""
				this.dialogTitle="新增小程序"
				
                this.getData()
            }
        },
		
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadAppListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		savePwd(){
			var that=this
			if (this.pwdForm.id<1){
				this.$message.warning("请先选择要修改的app")
				return
			}
			if (!this.pwdForm.password || this.pwdForm.length<6){
				this.$message.warning("新密码至少6为字符")
				return
			}
			that.$confirm("确定重置该系统的主账号登录密码吗？重置成功后该账号需要重新登录！", '提醒', {
			    closeOnClickModal: false,
			    type: "warning"
			}).then(res => {
				  resetAppPassworodApi({id:this.pwdForm.id,password:this.pwdForm.password}).then(res=>{
				  	if(res.code==2000){
						this.$message.success(res.msg)
				  		this.showResetPwdDialog=false
				  		this.getData()
				  	}else{
				  		this.$message.error(res.msg)
				  	}
				  })
				}).catch(()=>{})
			
		},
		saveRecharge(){
			
			var that=this
			let msg="确定为该用户充值金额："+this.rechargeForm.balance+"元吗？"
			that.$confirm(msg, '提醒', {
			    closeOnClickModal: false,
			    type: "warning"
			}).then(res => {
				  rechargeAppBalanceApi({id:this.rechargeForm.id,balance:this.rechargeForm.balance}).then(res=>{
				  	if(res.code==2000){
						this.$message.success(res.msg)
				  		this.showRechargeDialog=false
				  		this.getData()
				  	}else{
				  		this.$message.error(res.msg)
				  	}
				  })
				}).catch(()=>{})
			
			
		},
		savePayConfig(){
			var that=this
			let msg="确定修改支付参数吗？"
			that.$confirm(msg, '提醒', {
			    closeOnClickModal: false,
			    type: "warning"
			}).then(res => {
				 setAppPayConfigApi (this.pay_config_form).then(res=>{
				  	if(res.code==2000){
						this.$message.success(res.msg)
				  		this.showPayConfigDialog=false
				  		this.getData()
				  	}else{
				  		this.$message.error(res.msg)
				  	}
				  })
				}).catch(()=>{})
		},
		manageStatus(row,flag){
			let that = this
		
			let act_type=""
			let msg=""
			if(flag=="start"){
				act_type="start"
				msg="确定启用该小程序吗？"
			}
			if (flag=="stop"){
				act_type='stop'
				msg="确定停用确定启用该小程序吗？停用后该程序的用户将不能下单！"
			}
			if (flag=="add_menu"){
				act_type='add_menu'
				msg="确定为该小程序后台管理页面生成菜单吗？"
			}
			
			that.$confirm(msg, '提醒', {
			    closeOnClickModal: false,
			    type: "warning"
			}).then(res => {
			   
			    manageAppStatusApi({ id: row.id ,act_type:act_type}).then(res => {
			        if (res.code == 2000) {
			            that.$message.success(res.msg)
			            that.getData()
			        } else {
						row.status=!row.status
			            that.$message.warning(res.msg)
			        }
			    })
			}).catch(() => {
			
			})
		}

    },
    created() {
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>

<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="80%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false"   ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                   {{title}}-订单列表
                </div>
				<div>
					<p>订单合并发货原则：同一人同一天同一个收货地址（如果选择的物流也相同，系统16:50后会自动合单），支付时间超过10分的订单才会出在下面列表</p>
				</div>
                <el-table border :data="dataList"  @selection-change="handleSelectionChange" style="margin-top: 5px;">
					 <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column width="100" prop="id" label="id"></el-table-column>
                    <el-table-column width="180" prop="order_id" label="订单号"></el-table-column>
                    
                    <el-table-column width="110" prop="order_date" label="归属日期"></el-table-column>
                  
                    <el-table-column width="100" prop="product_count" label="商品数量"></el-table-column>
                    <el-table-column width="150" prop="address_name" label="收货人"></el-table-column>
                    <el-table-column width="150" prop="express_name" label="物流"></el-table-column>
                    <el-table-column width="150" prop="address_phone" label="收货人电话"></el-table-column>
                   
                    
                    <el-table-column width="400" prop="full_address" label="收货地址"></el-table-column>
                    <el-table-column   label="操作"></el-table-column>
                    <!-- <el-table-column min-width="110" prop="price" label="成本价" v-if="identity != 4"> -->
                </el-table>
				<el-form-item label="物流选择">
					
						<el-radio-group v-model="rule_id">
							<el-radio v-for="rule in deliveryRuleList" :key="rule.rule_id" :label="rule.express_name" :value="rule.rule_id"></el-radio>
						</el-radio-group>
					
				</el-form-item>
				
               
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button @click="submit" :loading="loadingSave" type="primary">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderDetail, orderLogisticsSearch,getOrdersDetailApi } from "@/api/api";
import { loadCustomerParentOrdersListApi,mergeOrdersApi } from "@/api/sass_orders";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { getStorage } from "@/utils/util"
export default {
    name: "mergeCustomerOrders",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
			
            dialogVisible: false,
            loadingSave: false,
            loadingLogistics: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: "",
			customer_id:0,
			address:"",
			order_date:'',
			act_type:'',
			dataList:[],
            title:"",
			selectedParentIdList:[],
			tips:"",
			rule_id:0,
			deliveryRuleList:[],
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
           
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen( item) {
            this.loadingTitle = "合并订单"
            this.dialogVisible = true
			this.customer_id=item.customer_id
			this.order_date=item.order_date
			this.address=item.full_address
			this.title=item.address_name+"("+item.order_date+")"
			this.getOderDetail()
          
        },
        handleSelectionChange(e){
			
			this.selectedParentIdList=[]
			e.forEach(item=>{
				this.selectedParentIdList.push(item.id)
				
			})
		},
        getOderDetail() {
            loadCustomerParentOrdersListApi({ customer_id: this.customer_id,order_date:this.order_date,address:this.address }).then(res => {
				console.log("order detail:",res)
                if (res.code === 2000) {
                    this.dataList = res.data.data_list
                    this.deliveryRuleList = res.data.rule_list
                }
            })
        },
		submit(){
			let msg=""
			if(this.selectedParentIdList.length<1){
				this.$message.warning("请选择要合并的订单！")
				return
			}
			if(this.rule<1){
				this.$message.warning("请选择合并后的发货物流选项！")
				return
			}
			if(this.selectedParentIdList.length>0){
				msg="确定将所选的订单合并发货吗？多次被选择的订单将会以最后一次的选择合单"
			}
		
			
			let that = this
			that.$confirm(msg,"提示", {
			    closeOnClickModal: false
			}).then(confirm_res => {
			    mergeOrdersApi({ customer_id:this.customer_id,parent_id_list:this.selectedParentIdList,order_date:this.order_date,rule_id:this.rule_id }).then(res => {
			        if (res.code == 2000) {
			            that.$message.success(res.msg)
			            that.handleClose()
			        } else {
			            that.$message.warning(res.msg)
			        }
			    })
			}).catch(() => {
			
			})
			
		},
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>


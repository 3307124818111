<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="80%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="detailData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    订单明细
                </div>
                <el-table border :data="detailData.order_list">
                    <el-table-column width="80" prop="index" label="序号"></el-table-column>
                    <el-table-column width="180" prop="order_id" label="订单号"></el-table-column>
                    <el-table-column width="90" prop="pos_no" label="货位号"></el-table-column>
                    <el-table-column width="120" prop="address_name" label="收货人"show-overflow-tooltip align="left"></el-table-column>
                    <el-table-column width="130" prop="address_phone" label="收货人电话"show-overflow-tooltip align="left"></el-table-column>
                    <el-table-column width="110" prop="total_product_count" label="商品数量" align="center" ></el-table-column>
                    <el-table-column width="110" prop="checkin_product_count" label="发货数量" align="center"></el-table-column>
                    <el-table-column width="110" prop="paid_postage" label="预付运费" align="center"></el-table-column>
                    <el-table-column width="110" prop="paid_box_fee" label="预付装箱费" align="center"></el-table-column>
                    <el-table-column width="110" prop="paid_packaging_fee" label="预付打包费" align="center"></el-table-column>
					
                    <el-table-column width="100" prop="total_orders_money" label="实付金额" align="center"></el-table-column>
                    <el-table-column width="100" prop="app_name" label="订单来源" align="left"></el-table-column>
                    <el-table-column width="100" prop="send_status_text" label="发货进度" style="color: red;">
						<template #default="scope">
							<span style="color: red;">{{scope.row.send_status_text}}</span>
						</template>
					</el-table-column>
                    <el-table-column width="100" label="操作">
						<template #default="scope">
							<span class="table-operate-btn" @click="toDetail(scope.row)"
								v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
						</template>
					</el-table-column>
                   
                </el-table>
				<div class="ly-title" style="margin-top:8px">
				    <span>发货单信息</span>
					
				</div>
				<div >
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="发货单Id：">
							    {{ detailData.merge_order_id }}
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="发货时间：">
							    {{ detailData.platform_send_time }}
							</el-form-item>
						</el-col>
						
					</el-row>
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="预付总运费：">
							    {{ detailData.paid_postage }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="实际运费：">
							    {{ detailData.real_total_postage }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="运费差额：">
							    {{ detailData.shortfall_postage }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<!-- <el-form-item label="运费差额支付状态：">
							    {{ detailData.shortfall_postage_status }}
							</el-form-item> -->
							<el-form-item v-if="detailData.shortfall_postage>=0" label="运费差额是否已退用户：">
							<p v-if="detailData.shortfall_postage_status==0">已退</p>
							<p v-if="detailData.shortfall_postage_status==2">未退</p>
							   
							</el-form-item> 
							<el-form-item v-if="detailData.shortfall_postage<0" label="用户是否已补交运费差额：">
								<p v-if="detailData.shortfall_postage_status==0">已补</p>
								<p v-if="detailData.shortfall_postage_status==1">未补</p>
							  
							</el-form-item>
							
						</el-col>
						
					</el-row>
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="预付装箱费：">
							    {{ detailData.paid_box_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="实际装箱费：">
							    {{ detailData.real_box_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="装箱差额：">
							    {{ detailData.shortfall_box_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<!-- <el-form-item label="装箱费差额支付状态：">
							    {{ detailData.shortfall_box_fee_status }}
							</el-form-item> -->
							
							<el-form-item v-if="detailData.shortfall_box_fee>=0" label="装箱费是否已退用户：">
							<p v-if="detailData.shortfall_box_fee_status==0">已退</p>
							<p v-if="detailData.shortfall_box_fee_status==2">未退</p>
							
							</el-form-item>
							<el-form-item v-if="detailData.shortfall_box_fee<0" label="用户是否已补交装箱费：">
								<p v-if="detailData.shortfall_box_fee_status==0">已补</p>
								<p v-if="detailData.shortfall_box_fee_status==1">未补</p>
							   
							</el-form-item>
						</el-col>
						
					</el-row>
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="预付打包费：">
							    {{ detailData.paid_package_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="实际打包费：">
							    {{ detailData.real_package_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="打包差额：">
							    {{ detailData.shortfall_package_fee }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<!-- <el-form-item label="打包费差额支付状态：">
							    {{ detailData.shortfall_package_fee_status }}
							</el-form-item> -->
							
							<el-form-item v-if="detailData.shortfall_package_fee>=0" label="打包费差额是否已退用户：">
							<p v-if="detailData.shortfall_package_fee_status==0">已退</p>
							<p v-if="detailData.shortfall_package_fee_status==2">未退</p>
							
							</el-form-item>
							<el-form-item v-if="detailData.shortfall_package_fee<0" label="用户是否已补交打包费差额：">
								<p v-if="detailData.shortfall_package_fee_status==0">已补</p>
								<p v-if="detailData.shortfall_package_fee_status==1">未补</p>
							   
							</el-form-item>
						</el-col>
						
					</el-row>
				</div>
                <div class="ly-title">
                    <span>地址信息</span>
                </div>
                <div>
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="联系人：">
							    {{ detailData.address_name }}
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="联系电话：">
							    {{ detailData.address_phone }}
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="详细地址：">
							    {{ detailData.address }}
							</el-form-item>
						</el-col>
					</el-row>
					
				</div>
                
                
               
               
                <div class="ly-title">
                    物流信息
                </div>
                <el-row :gutter="20" >
                    <el-col :span="6">
                        <el-form-item label="物流公司：">
                            {{ detailData.express_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" >
                        <el-form-item label="物流单号：">
                           <el-tag v-for="item in detailData.express_no_list" :key="item">{{item}}</el-tag>
                        </el-form-item>
                    </el-col>
                   
                </el-row>
                

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderDetail, orderLogisticsSearch,getOrdersDetailApi } from "@/api/api";
import { loadMergeOrdersDetailApi } from "@/api/sass_orders";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { getStorage } from "@/utils/util"
export default {
    name: "mergeOrderDetail",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingLogistics: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: 0,
			agent_id:0,
			order_date:'',
			act_type:'',
			dataList:[],
			dataType:"",
            detailData: {
                id:0,
				
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
           
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item_id,data_type) {
			console.log("from",data_type,"item_id:",item_id)
            this.loadingTitle = "发货单详情"
            this.dialogVisible = true
			this.id=item_id
			this.dataType=data_type
			this.getOderDetail()
      
    //         }
        },
		toDetail(row){
			console.log("row:",row)
			 this.$emit('handleDetail',row)
			
		},
      
        getOderDetail() {
			
            loadMergeOrdersDetailApi({ id:this.id,data_type:this.dataType }).then(res => {
                if (res.code === 2000) {
                    this.detailData=res.data.data
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>


import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadPrintListApi = (params={}) =>ajaxGet({url:'mall/print/data', params})

export const loadAgentOrdersPrintApi = (params={}) =>ajaxGet({url:'mall/agent/orders/print/data', params})
export const loadAgentUserOrdersPrintApi = (params={}) =>ajaxGet({url:'mall/agent/users/orders/print/data', params})
export const loadUserOrdersPrintApi = (params={}) =>ajaxGet({url:'mall/users/orders/print/data', params})

export const loadCustomerOrdersPrintDataApi= (params={}) =>ajaxGet({url:'mall/print/customer/orders/data', params})
export const loadUserOrdersPrintDetailApi= (params={}) =>ajaxGet({url:'mall/print/user/orders/detail', params})

// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
// export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
// export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})
<template>
	<div ref="orderPrint" class="print-template">
		<div v-for="item in data_list" style="font-size: 14px;padding-top: 18px;page-break-after: always;">
			<div class="top-title" style="margin-top: 16px;">
				<div style="display: flex;">
					<span style="font-size: 16px;">{{item.print_platform_name}}</span>
					<span style="margin-left: 8px;">{{item.date}}</span>
					<span style="margin-left: 12px;">{{item.cur_page}}/{{item.total_page}}</span>
				</div>
			</div>
			<div class="pos_no-area" style="margin-top: 6px;">
				<div style="display: flex;">
					<div style="width: 120px;height: 50px;line-height: 50px;font-size: 20px;font-weight: bold; border: 1px #000000 solid;margin-left: 2px;text-align: center; align-items: center;justify-content: center;">
						{{item.pos_no}}
					</div>
					<div >
						<div >
						<span style="font-size: 18px; margin-left: 8px;margin-top: 2px;">{{item.address_name}}</span>	
						</div>
						<div style="margin-top: 3px;">
							<span style="font-size: 16px; margin-left: 8px;margin-top: 2px;">{{item.address_phone}}</span>
						</div>
						
					</div>
					
				</div>
			</div>
			<div class="pickup-text" style="margin-top: 6px;">
				<span>{{item.pickup_way_name}}</span>
			</div>
			<div class="address" style="margin-top: 6px;">
				<div style="margin-top:5px ;">
					<span style="font-size: 16px;font-weight: bold;">{{item.address}}</span>
				</div>
				<div style="margin-top: 5px;">
					<span style="font-size: 14px;">{{item.address_detail}}</span>
				</div>
			</div>
			<div class="total_product">
				<div style="margin-top:6px ;">
					<span style="font-size: 14px;font-weight: bold;">总数：{{item.total_product_count}}扎</span>
				</div>
			</div>
			<div class="product" style="margin-top: 4px;">
				
					<div v-for="detail_list in  item.print_data_list"  style="margin-top:2px">
						<div v-if="detail_list.length==2" style="width: 100%;display: flex;">
							<!-- 只有花材的数据长度才有长可能是2 -->
							<span style="font-size: 9px;width: 50%;">{{detail_list[0].data.product_name}} X{{detail_list[0].data.count}}</span>	
							
							<span style="font-size: 9px;width: 50%;">{{detail_list[1].data.product_name}} X{{detail_list[1].data.count}}</span>
								
						</div>
						<div v-if="detail_list.length<2" style="width: 100%;display: flex;">
							
							<div v-if="detail_list[0].data_type=='product'">
								<span style="font-size: 9px;width: 50%;">{{detail_list[0].data.product_name}} X{{detail_list[0].data.count}}</span>
							</div>
							<div v-if="detail_list[0].data_type=='first_mark'" style="margin-top: 8px;">
								<!-- 备注的数据长度为1 -->
								<span style="font-size: 9px;width: 100%;">备注：{{detail_list[0].data}} </span>
							</div>
							<div v-if="detail_list[0].data_type=='mark'" >
								<!-- 备注的数据长度为1 -->
								<span style="font-size: 9px;width: 100%;">备注：{{detail_list[0].data}} </span>
							</div>
							
						</div>
						
						
					</div>
				
				<!-- <div class="right" style="width: 200px;">
					<div v-for="product in  item.left_product_list" :key="product.product_name" style="display: flex;margin-top:2px">
						<span style="font-size: 9px;">{{product.product_name}} X{{product.count}}</span>
					</div>
				</div> -->
			</div>
		
		
			<!-- <div style="display: flex;align-items: center;"> -->
			<!-- <img :src="formData.zyImg" style="width:40px;height: 40px;border-radius: 60%;object-fit:contain;" /> -->
			<!-- <div style="padding-left: 5px;"> -->
			<!-- <h6 style="font-size: 16px;">{{ formData.zyName }}</h6> -->
			<!-- <div style="font-size: 11px;padding-top: 5px;">{{ formData.date }}</div> -->
			<!-- </div> -->

			<!-- </div> -->
			<!-- <h1 style="font-size: 25px;padding-top: 10px;padding-bottom: 10px;">{{ formData.goods_no }}</h1> -->
			<!-- <div style="font-size: 20px;padding-bottom: 10px;">{{ formData.express_name }}</div> -->
			<!-- <div style="font-size: 11px;"> -->
			<!-- <span>{{ formData.address_name }}</span> -->
			<!-- <span style="padding-left: 5px;">{{ formData.phone }}</span> -->
			<!-- <span style="padding-left: 5px;">{{ formData.address_detail }}</span> -->
			<!-- </div> -->
			<!--   <div style="height: 260px;padding-top: 20px;font-size: 10px;">
            <span>{{ formData.goods_info }}</span>
        </div> -->
			<!-- <div style="font-size: 12px;">
            <div>总数：{{ formData.total_count }}扎</div> 
            <div style="padding-top: 3px;">客服：{{ formData.service_phone }}</div> -->
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	import print from '@/utils/print'
	import {
		loadUserOrdersPrintApi
	} from '@/api/print'
	import {
		fmallOrderPrintOrder
	} from '@/api/api'
	import {
		deepClone
	} from "@/utils/util"
	export default {
		name: "printUsersOrders",
		emits: ['closed'],
		data() {
			return {
				id_list: [],
				order_date: "",
				data_list: [],
				data_type: "",
				formData: {

					date: '2024-06-13',
					goods_no_area: "A区",
					goods_no: '109',
					ji_hua_bao: "集花宝分捡中心",
					service_phone: "18987573857",
					address_name: '赵钱孙李',
					address_phone: "18788556355",
					address: "云南省 昆明市 呈贡区 ",
					address_detail: "斗南集花宝",
					express_name: "和硕冷链"
				},
			}
		},
		methods: {
			handleClose() {
				this.$emit('closed')
			},
			handleOpen(item) {

				if (item) {
					let data = deepClone(item)
					this.id_list = data.id_list
					
				}

				// this.$nextTick(() => {
				//   this.handlePrint()
				// })
				// if (item) {
				//     this.formData = deepClone(item)
				// }
				this.getPrintData()
			},
			handlePrint() {
				print(this.$refs.orderPrint)
				// const PrintContent = this.$refs.orderPrint
				// const PrintWindow = window.open("", "_blank")
				// const htmlContent = `
				//     <html>
				//         <head>
				//             <title></title>
				//             <style>
				//             .print-template {
				//                 display: none;
				//                 /* 默认不显示 */
				//             }
				//             @media print {
				//                 .print-template {
				//                     display: block;
				//                     /* 打印时显示 */
				//                 }

				//                 /* 去除页眉和页脚 */
				//                 @page {
				//                     margin-top: 0;
				//                     margin-bottom: 0;
				//                 }
				//             }
				//             </style>
				//         </head>
				//         <body>
				//             ${PrintContent.innerHTML}
				//         </body>
				//     </html>
				// `
				// PrintWindow.document.write(htmlContent)
				// PrintWindow.document.close()
				// PrintWindow.onload = () => {
				//     PrintWindow.print()
				//     PrintWindow.close()
				// }
			},
			getPrintData() {

				loadUserOrdersPrintApi({
					
					id_list: JSON.stringify(this.id_list),
					
				}).then(res => {
					if (res.code === 2000) {
						if (res.data) {
							this.data_list = res.data
                          
							this.$nextTick(() => {
								this.handlePrint()
							})

							// res.data.forEach(item=>{
							// 	 this.formData = item

							// })
						}


					}
				})
			}
		}
	}
</script>
<style scoped>
	.print-template {
		display: none;
		/* 默认不显示 */
	}

	@media print {
		.print-template {
			display: block;
			/* 打印时显示 */
		}

		/* 去除页眉和页脚 */
		@page {
			margin-top: 0;
			margin-bottom: 0;
			margin-left: 18px;
			margin-right: 18px;
		}
	}
</style>